import Cookies from 'js-cookie';

export default {
  init() {
    /*!
     * IE10 viewport hack for Surface/desktop Windows 8 bug
     * Copyright 2014-2017 The Bootstrap Authors
     * Copyright 2014-2017 Twitter, Inc.
     * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
     */
    // See the Getting Started docs for more information:
    // https://getbootstrap.com/getting-started/#support-ie10-width
    (function () {
      'use strict'
      if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
        var msViewportStyle = document.createElement('style')
        msViewportStyle.appendChild(
          document.createTextNode(
            '@-ms-viewport{width:auto!important}'
          )
        )
        document.head.appendChild(msViewportStyle)
      }
    }());
    //Android stock browser select fix
    $(function () {
      var nua = navigator.userAgent
      var isAndroid = (nua.indexOf('Mozilla/5.0') > -1 && nua.indexOf('Android ') > -1 && nua.indexOf('AppleWebKit') > -1 && nua.indexOf('Chrome') === -1)
      if (isAndroid) {
        $('select.form-control').removeClass('form-control').css('width', '100%')
      }
    });
    $("nav .dropdown").mouseenter(function(){
      $(this).addClass("open");
    }).mouseleave(function(){
      $(this).removeClass("open");
    });
    const buttonMarkup = '<li class="mega-menu-btns"><a href="/who-we-are/contact-us/" class="btn btn-primary">Contact Us <i class="fa fa-mobile-alt"></i></a><a href="/locations/" class="btn btn-primary">Find a Location <i class="fa fa-map-marker-alt"></i></a><a href="/work-with-us/" class="btn btn-primary">View Jobs <i class="fa fa-address-card"></i></a></li>';
    $("#main-menu .mega-menu-parent > .dropdown-menu").append(buttonMarkup);
    $("#chc1-mobile-menu .dropdown > a").click(function (n) {
      n.preventDefault(); n.stopPropagation();
      if ($(this).hasClass("expanded")) {
        location.href = this.href;
      } else {
        $(this).find(".fa-caret-right").addClass("rotate-arrow");
        $(this).parent().find(".dropdown-menu").first().show();
        $(this).addClass("expanded");
      }
    });
    $('.cleave-phone input').toArray().forEach(function (field) {
      new Cleave(field, {
        numericOnly: true,
        blocks: [0, 3, 0, 3, 4],
        delimiters: ["(", ")", " ", "-"]
      });
    });
    $('.cleave-dob input').toArray().forEach(function (field) {
      new Cleave(field, {
        date: true,
        delimiter: '/',
        datePattern: ['m', 'd', 'Y']
      });
    });
    var radio = jQuery('#symptoms-radio-cont input[type="radio"]');
    var prev = null;
    for (var i = 0; i < radio.length; i++) {
        radio[i].addEventListener('change', function() {
            if (this !== prev) {
                prev = this;
                if (prev.value == 'Yes') {
                    jQuery('#symptoms-date-cont').show();
                }
                else {
                    jQuery('#symptoms-date-cont').hide();
                }
            }
        });
    }
    //video modal (current on News page(s))
    $(".vid-trigger").click(function(n){
      n.preventDefault();
      var videoSrc = $(this).attr("data-src");
      $("#externalVideoModalEmbed").attr("src", videoSrc + "?autoplay=1&showinfo=0&modestbranding=1&rel=0&mute=1");
    });
    $("#externalVideoModal").on("hide.bs.modal", function(e) {
      $("#externalVideoModalEmbed").attr("src", "about:blank");
    });
    //VERY simple validation check, only looks if value is defined
    function checkValidation(step) {
      var allRequiredFieldsGood = true;
      $("#"+step+" .required").each(function(n){
        if(!$(this).val().length) {
          allRequiredFieldsGood = false;
        }
      });
      return allRequiredFieldsGood;
    }

    //newsletter signup
    var currentStep = "regStep1";

    //set completed or not completed required fields
    $(".form-control.required").each(function(){
      $(this).on('input', function() {
        if($(this).val().length) {
          if(!$(this).hasClass("completed"))
            $(this).addClass("completed");
        }
        else {
          if($(this).hasClass("completed"))
            $(this).removeClass("completed");
        }
      })
    });
    $(".news-signup button").click(function(n){
      if($(this).data("go-to-step").length) {
        //check to see if step is completed
        var goToStep = $(this).data("go-to-step");
        if(goToStep == "doneIgnored") {
          $(".news-signup").removeClass("active");
          Cookies.set('DISPLAY_NEWSLETTER', '0', { expires: 7, path: '/' });
        }
        else if (goToStep == "doneSubmit") {
          $(".news-signup").removeClass("active");
          Cookies.set('DISPLAY_NEWSLETTER', '0', { expires: 10000, path: '/' });
        }
        else {
          if(checkValidation(currentStep)) {
            $(".reg-step.active").removeClass("active");
            $(".progress-item.active").removeClass("active");
            $("#"+goToStep).addClass("active");
            if(goToStep == "regStep2")
            {
              var url = "/api.php?mc=0&eml=" + $("#nwsEmail").val() + "&fn=" + $("#nwsFirst").val() + "&ln=" + $("#nwsLast").val();
              $.getScript(url, function(response, status) {
                // console.log(response);
              });
            }
            if(goToStep == "regStep3") {
              var url = "/api.php?mc=0&eml=" + $("#nwsEmail").val() + "&city=" + $("#nwsCity").val() + "&state=" + $("#nwsState").val() + "&org=" + $("#nwsOrg").val() + "&role=" + $("#nwsRole").val();
              $.getScript(url, function(response, status) {
                // console.log(response);
              });
              Cookies.set('DISPLAY_NEWSLETTER', '0', { expires: 10000, path: '/' });
            }
            currentStep = goToStep;
          }
          else {
            n.preventDefault();
            n.stopPropagation();
            alert("You have to fill out all of the required fields!");
          }
        }
      }
      else {
        console.log("no step defined.");
      }
    });
    $('iframe[src*="youtube"], iframe[src*="vimeo"]').each(function(){
      if(!$(this).parent().hasClass("embed-responsive") && !$(this).parent().hasClass("wp-block-embed__wrapper")) {
        $(this).addClass("embed-responsive-item").wrap("<div class='embed-responsive embed-responsive-16by9'></div>");
      }
    });
  },
  finalize() {

    var displayNewsletter = 0;
    if(Cookies.get('DISPLAY_NEWSLETTER') === undefined) {
      Cookies.set('DISPLAY_NEWSLETTER', '1', { expires: 7, path: '/' });
      displayNewsletter = 1;
    }
    else if (Cookies.get('DISPLAY_NEWSLETTER') == '0') {
      displayNewsletter = 0;
    }
    else {
      displayNewsletter = 1;
    }
    if(displayNewsletter) {
      $(".news-signup").addClass("active");
    }

  },
};
