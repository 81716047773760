export default {

  init() { },

  finalize() {
    //group filtering
    (function ($) {
      'use strict'
      //clear filters
      $(".clear-filters").click(function() {
        $(".filter-specialty-btn button.active").removeClass('active');
        $(".groups-list > .no-results").addClass("hide").prop("style",false);
        $(".groups-list > div:not(.no-results)").fadeIn().removeClass("hide");
      });
      //text change
      $('#search-group').on('input',function(e){
          var searchText = $(this).val().toLowerCase();
          $(".filter-specialty-btn button.active").removeClass('active');
          if(searchText.length) {
            $(".groups-list > div").addClass("hide").prop("style",false);
            $(".groups-list > div:not(.no-results)").filter(function() {
              $(this).toggle($(this).text().toLowerCase().indexOf(searchText) > -1);
            });
          }
          else {
            $(".groups-list > .no-results").addClass("hide").prop("style",false);
            $(".groups-list > div:not(.no-results)").fadeIn().removeClass("hide");
          }
      });
      //button change
      $(".filter-specialty-btn button.btn-pill").click(function() {
          $('#search-group').val('');
          if(!$(this).hasClass("active")) {
              $(this).addClass("active");
          }
          else {
              $(this).removeClass("active");
          }
          var classes = [];
          
          $(".filter-specialty-btn button.active").each(function(){
              classes.push($(this).val());
          });
          if(classes !== null) {
            if (!$(".filter-specialty-btn button.active").length) {
                $(".groups-list > .no-results").addClass("hide").prop("style",false);
                $(".groups-list > div:not(.no-results)").fadeIn().removeClass("hide");
            } else {
              $(".groups-list > div").addClass("hide").prop("style",false);
              $(".groups-list ."+classes.join(".")).fadeIn().removeClass("hide");
            }
          } else {
            $(".groups-list > .no-results").addClass("hide").prop("style",false);
            $(".groups-list > div:not(.no-results)").fadeIn().removeClass("hide");
          }
          if(!$(".groups-list > div:not(.hide):not(.no-results)").length) {
            $(".groups-list > .no-results").fadeIn().removeClass("hide");
          }
      });
    }(jQuery));
  },

};