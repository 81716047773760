//style
import '../scss/style.scss';
//bootstrap default js functionality
import 'bootstrap/dist/js/bootstrap.js';
//slider
import slick from 'slick-carousel';
import rwdImageMaps from 'image-map-resizer';
//Cleave
import Cleave from 'cleave.js';
//router for specific page js
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import leadershipTeam from './routes/leadershipTeam';
import pageTemplateFaq from './routes/pageTemplateFaq';
import timeline from './routes/timeline';
import locations from './routes/locations';
import groups from './routes/groups';
// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebook, faTwitter, faYoutube, faLinkedin, faTwitterSquare, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faSearch, faCaretRight, faBars, faTimes, faArrowCircleRight, faMobileAlt, faMapMarkerAlt, faAddressCard, faChevronUp, faChevronRight, 
  faChevronLeft, faArrowRight, faArrowLeft, faCog, faExclamationTriangle, faCircle, faChevronCircleRight, faFilter, faTimesCircle, faWifi } from "@fortawesome/free-solid-svg-icons";
// add the imported icons to the library
library.add(faFacebook, faTwitter, faYoutube, faSearch, faCaretRight, faBars, faTimes, faLinkedin, faTwitterSquare, faArrowCircleRight, faMobileAlt, 
  faMapMarkerAlt, faAddressCard, faChevronUp, faChevronRight, faChevronLeft, faArrowLeft, faArrowRight, faCog, faExclamationTriangle, faInstagram, faCircle, faChevronCircleRight, faFilter, faTimesCircle, faWifi);
// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** First page */
  home,
  /** Custom */
  leadershipTeam,
  pageTemplateFaq,
  timeline,
  locations,
  groups
});
/** Load Events */
jQuery(document).ready(() => routes.loadEvents());