function closeProfile() {
  //clean
  $(".leader-profile.active").removeClass("active");
  $(".leader-profile-expand.open, .leader-profile-expand-mobile.open").removeClass("open");
  $(".leader-profile-expand > div, .leader-profile-expand-mobile > div").hide();
}
export default {
  init() {
    //handle click of profile
    $(".leader-profile").click(function(n){
      //variables
      let rowExpandId = $(this).data("rowexpand"),
          profileId = $(this).attr("ID"),
          expandContainer = ".leader-profile-expand",
          expandProfile = ".profile-expand-";
      //change targets if mobile
      if($("#test-small").css("float") === "none") {
        rowExpandId = $(this).data("rowexpandmobile");
        expandContainer = ".leader-profile-expand-mobile";
        expandProfile = ".mobile-profile-expand-";
      }
      //if we are already looking at the profile
      if(!$(this).hasClass("active")) {
        $(".leader-profile.active").removeClass("active");
        $(this).addClass("active");
        //if the profile viewing container is not open
        if (!$(expandProfile+rowExpandId).hasClass("open")) {
          $(expandContainer+".open").removeClass("open");
          $(expandProfile+rowExpandId).addClass("open");
          $(expandProfile+rowExpandId+" > div").hide();
          $(expandContainer+" ."+profileId+"-cont").fadeIn(1000);
        }
        //profile viewing container is open
        else {
          $(expandProfile+rowExpandId+" > div").hide();
          $(expandContainer+" ."+profileId+"-cont").fadeIn(1000);
        }
      }
      //if we are not looking at the profile already
      else {
        $(this).removeClass("active");
        $(expandContainer+".open").removeClass("open");
        $(expandProfile+rowExpandId+" > div").hide();
        $(expandContainer+" ."+profileId+"-cont").hide();
      }
    });
    $(".profile-close").click(function(n){
      n.preventDefault(); n.stopPropagation();
      closeProfile();
    });
  },
  finalize() {
    $(window).resize(function(n){
      closeProfile();
    })
  },
};
