export default {

  init() {
    $('select').each(function () {
      var $this = $(this), numberOfOptions = $(this).children('option').length;

      $this.addClass('select-hidden');
      $this.wrap('<div class="select"></div>');
      $this.after('<div class="select-styled"></div>');

      var $styledSelect = $this.next('div.select-styled');
      $styledSelect.text($this.children('option').eq(0).text());

      var $list = $('<ul />', {
        'class': 'select-options'
      }).insertAfter($styledSelect);

      for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val()
        }).appendTo($list);
      }

      var $listItems = $list.children('li');

      $styledSelect.click(function (e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function () {
          $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
      });

      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        //update faq
        $(".faq-section.show").removeClass("show").addClass("hide");
        $("#faq" + $(this).attr('rel')).addClass("show").removeClass("hide");
        //update btn
        $(".btn-faq.active").removeClass("active");
        $(".btn-faq[value="+$(this).attr('rel')+"]").addClass("active");
      });

      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
      });

    });
  },
  finalize() {
    $(".btn-faq").click(function(n){
      if(!$(this).hasClass("active")) {
        $(".btn-faq.active").removeClass("active");
        $(this).addClass("active");
        $(".faq-section.show").removeClass("show").addClass("hide");
        $("#faq"+$(this).val()).addClass("show").removeClass("hide");
        //update dropdown
        $("#faq-select").val($(this).val());
        $(".select-styled").text($(this).text()).removeClass('active');
      }
    });
  },
};
