//slider config
const assetSlideSettings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  infinite: false,
  centerMode: true,
  focusOnSelect: true,
  asNavFor: '#content-slider #slide-data',
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        centerMode: false,
        dots: false
      }
    }
  ]
};
const contentSlideSettings = {
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: '#asset-slider',
  dots: false,
  infinite: false,
  fade: true,
  arrows: true,
  prevArrow: $('#new-slide-left'),
  nextArrow: $('#new-slide-right'),
  responsive: [
    {
      breakpoint: 991,
      settings: {
        dots: true
      }
    }
  ]
};
//clean sliders/load show
function cleanSliders() {
  $("#slide-loading").removeClass("hide").addClass("show");
  $("#asset-slider, #content-slider #slide-data").slick('unslick').empty();
}
//get slider data for category
function getTimeLineData(category) {
  var categoryId = {
    "1970s": 35,
    "1980s": 36,
    "1990s": 37,
    "200005": 38,
    "200610": 39,
    "201115": 40,
    "201619": 41,
    "202021": 4234
  };
  $.ajax("/wp-json/wp/v2/timeline?categories=" + categoryId[category], {
    success: function (data) {
      function sortByDate(a, b) {
        return new Date(a.acf.date).getTime() - new Date(b.acf.date).getTime();
      }
      $("#slide-error").removeClass("show").addClass("hide");
      var sortedData = data.sort(sortByDate);
      updateSliders(sortedData);
    },
    error: function () {
      console.log("Error retrieving data!");
      $("#slide-loading, #content-slider").removeClass("show").addClass("hide");
      $("#slide-error").removeClass("hide").addClass("show");
    }
  });
}
//update slider with data
function updateSliders(slideData) {
  //output variables
  var assetSlideOutput = "",
      contentSlideOutput = "";
  //loop through
  for (var slide = 0, x = slideData.length; slide < x; slide++) {
    //check if video or image
    if (slideData[slide].acf.youtube_video_id.length) {
      const slideAssetVideo = slideData[slide].acf.youtube_video_id;
      var assetOutput = '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://www.youtube.com/embed/' + slideAssetVideo + '"></iframe></div>';
    }
    else if (slideData[slide].acf.vimeo_video_id.length) {
      const slideAssetVideo = slideData[slide].acf.vimeo_video_id;
      var assetOutput = '<div class="embed-responsive embed-responsive-16by9"><iframe class="embed-responsive-item" src="https://player.vimeo.com/video/' + slideAssetVideo + '?portrait=0"></iframe></div>';
    }
    else if (slideData[slide].acf.slide_image.length) {
      const slideAssetLink = slideData[slide].acf.slide_image;
      var assetOutput = '<div class="embed-responsive embed-responsive-16by9 blue-bg"><img class="w-100 embed-responsive-item" style="object-fit:contain;" src="' + slideAssetLink + '" /></div>';
    }
    const slideDate = slideData[slide].acf.date;
    const slideShowYear = slideData[slide].acf.display_year;
    var slideDateOutput = '',
        slideDatePieces = [];
    if (slideShowYear) {
      slideDatePieces = slideDate.split("/");
      slideDateOutput = slideDatePieces[2];
    }
    else {
      slideDatePieces = slideDate.split("/");
      slideDateOutput = slideDatePieces[0]+"/"+slideDatePieces[2];
    }
    const slideTitle = slideData[slide].title.rendered;
    const slideDescription = slideData[slide].content.rendered;
    //build sliders
    assetSlideOutput += '<div>'+assetOutput+'</div>';
    contentSlideOutput += '<div><h5 class="thick">' + slideDateOutput + '</h5><h3 class="thin">' + slideTitle + '</h3>' + slideDescription + '</div>';
  }
  //put slider output in DOM
  $("#slide-loading").removeClass("show").addClass("hide");
  $("#asset-slider").html(assetSlideOutput).slick(assetSlideSettings);
  $("#content-slider").addClass("show").removeClass("hide");
  $("#content-slider #slide-data").html(contentSlideOutput).slick(contentSlideSettings);
}
export default {
  init() {
    //get data for initial date
    getTimeLineData("1970s");
  },
  finalize() {
    //setup custom selects
    $('select').each(function () {
      var $this = $(this), numberOfOptions = $(this).children('option').length;
      $this.addClass('select-hidden');
      $this.wrap('<div class="select"></div>');
      $this.after('<div class="select-styled"></div>');
      var $styledSelect = $this.next('div.select-styled');
      $styledSelect.text($this.children('option').eq(0).text());
      var $list = $('<ul />', {
        'class': 'select-options'
      }).insertAfter($styledSelect);
      for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
          text: $this.children('option').eq(i).text(),
          rel: $this.children('option').eq(i).val()
        }).appendTo($list);
      }
      var $listItems = $list.children('li');
      $styledSelect.click(function (e) {
        e.stopPropagation();
        $('div.select-styled.active').not(this).each(function () {
          $(this).removeClass('active').next('ul.select-options').hide();
        });
        $(this).toggleClass('active').next('ul.select-options').toggle();
      });
      $listItems.click(function (e) {
        e.stopPropagation();
        $styledSelect.text($(this).text()).removeClass('active');
        $this.val($(this).attr('rel'));
        $list.hide();
        //clean sliders
        cleanSliders();
        //update sliders
        getTimeLineData($(this).attr('rel'));
        //update btn
        $(".btn-faq.active").removeClass("active");
        $(".btn-faq[value=" + $(this).attr('rel') + "]").addClass("active");
      });
      $(document).click(function () {
        $styledSelect.removeClass('active');
        $list.hide();
      });
    });
    //handle clicks
    $(".btn-faq").click(function(n){
      if(!$(this).hasClass("active")) {
        $(".btn-faq.active").removeClass("active");
        $(this).addClass("active");
        //clean sliders
        cleanSliders();
        //update sliders
        getTimeLineData($(this).val());
        //update dropdown
        $("#faq-select").val($(this).val());
        $(".select-styled").text($(this).text()).removeClass('active');
      }
    });
  },
};
