export default {

  init() {
    $('map').imageMapResize();
  },

  finalize() {

  },

};